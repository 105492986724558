import { gql, useQuery } from "@apollo/client";
import React from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";

const TERMS_OF_SERVICE = gql`
  query getTermsOfService {
    shop {
      termsOfService {
        body
        title
      }
    }
  }
`;

const ShippingPolicy = () => {
  const { loading, data } = useQuery(TERMS_OF_SERVICE);

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div className="container mx-auto my-8 px-4">
          <h1 className="font-bold text-3xl mb-4">{data.shop.termsOfService.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.shop.termsOfService.body }}
          ></div>
        </div>
      )}
    </Layout>
  );
};

export default ShippingPolicy;
